#app-container {
  padding: 20px;
  height: 100vh;
}

.portrait-orientation-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  padding: 20px;
  background: rgba(0, 0, 0, .8);
  .portrait-icon {
    color: #ffffff;
    font-size: 60px;
  }
}

@media only screen and (max-width: 1280px) {
  #app-container {
    height: auto;
  }
}

.hidden {
  display: none;
}

.audio-wave-container {
  height: 100%;
}

.dropzone-container {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
